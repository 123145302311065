body {
  margin: 0;
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 37px;
  font-weight: bold;
  color: #303d4d;
  line-height: 69px;
}

h2 {
  font-size: 34px;
  font-weight: bold;
  color: #333;
  line-height: 45px;
}

h3 {
  font-size: 23px;
  font-weight: bold;
  color: #202020;
  line-height: 43px;
}

h4 {
  font-size: 18px;
  font-weight: bold;
  color: #202020;
  line-height: 33px;
  letter-spacing: 0;
}

a {
  color: #7f32de;
}

.custom-chart-tooltip {
  color: white;
}

.error {
  color: red;
}

@media only print {
  button {
    display: none !important;
  }
}

