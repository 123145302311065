div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 300px;
    border-radius: 12px;
    display: inline-block;
    border: solid 1px gray;
margin-top: 10px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border-radius: 12px;
    padding: 0 4px;
    border: none;
    height: 50px;
    outline: #7F32DE 2px solid;
    background-color: white;
  
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    margin: 10px;
}
button.ReactTags__remove{
    width: 20px;
    height: 20px;
    border-radius: 50%;
   border: solid 1px red;
   color: red;
   background-color: white;
   margin-left: 5px;
}
