#attendance [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

#attendance [type="file"] + label {
  width: 180px;
  height: 46px;
  background: #6418c3;
  border: none;
  border-radius: 16px;
  font-family: "Cairo", sans-serif;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: inherit;
  font-weight: 800;
  margin-bottom: 1rem;
  outline: none;
  padding: 0px 63px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin-left: 20px;
  font-size: 16px;
}
